import React from 'react'
import styled from 'styled-components'

// 💅
const ButtonDisabledBookNow = styled.button `
    font-family: 'Open Sans';
    background-color: #F55700;
    border: 0.1rem solid #F55700;
    border-radius: 3px;
    opacity: .3 !important;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px !important;
    font-weight: 700;
    height: 2.425rem;
    letter-spacing: 0.1rem;
    padding: 0 0.5rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
      &:focus,
      &:hover {
        cursor: not-allowed;
    }
`;

// 🕳
const Button = (props) => {
  return (
      <ButtonDisabledBookNow>{props.buttonText}</ButtonDisabledBookNow>
  )
}
export default Button