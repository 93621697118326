import React from 'react'
import styled from 'styled-components'

import ButtonDisabledBookNow from '../AccordionComponents/AccordionButtons/ButtonDisabledBookNow.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faIgloo, faFileAlt, faCopy, faChevronCircleDown, faBookmark } from '@fortawesome/free-solid-svg-icons'

library.add(faIgloo, faFileAlt, faCopy, faChevronCircleDown, faBookmark)


const AccordionComponentDisabledQuotes = styled.div`
.es-c-shipment-accordion {
  .es-cg-shipment-accordion {
    // 💅
    &:hover {
      background-color: #e7edf0;
      cursor: pointer;
    }
    // 🎛
    font-family: "Open Sans";
    // 🎨
    border-radius: 3px;
    background-color: #f7f7f7;
    margin-bottom: 3px;
    padding-top: 4px;
    padding-bottom: 4px;
    // 🖋
    color: #141b1f;
    font-size: 12px;
    font-weight: 400;
    // 📰
    display: grid;
    grid-template-rows: minmax(0.5fr, 5fr) 0.5fr;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
    "sg_shipment sg_status sg_origin sg_destination sg_pickup sg_delivery sg_carrier sg_total_cost sg_bol_btn sg_copy_btn sg_book_now sg_carrot_btn";

    span {
      font-size: 12px;
      color: #666;
      font-weight: 400;
    }
    .sg-shipment {
      grid-area: sg_shipment; // 🗺
      align-self: center;
      text-decoration: underline;
      color: #0033a1;
      padding-left: 20px;
    }
    .sg-status {
      grid-area: sg_status; // 🗺
      align-self: center;
    }
    .sg-origin {
      grid-area: sg_origin; // 🗺
      align-self: center;
    }
    .sg-destination {
      grid-area: sg_destination; // 🗺
      align-self: center;
    }
    .sg-pickup {
      grid-area: sg_pickup; // 🗺
      align-self: center;
    }
    .sg-delivery {
      grid-area: sg_delivery; // 🗺
      align-self: center;
    }
    .sg-carrier {
      grid-area: sg_carrier; // 🗺
      align-self: center;
    }
    .sg-total-cost {
      grid-area: sg_total_cost; // 🗺
      align-self: center;
    }
    .sg-bol-btn {
      grid-area: sg_bol_btn; // 🗺
      align-self: center;
      justify-self: center;
    }
    .sg-copy-btn {
      grid-area: sg_copy_btn; // 🗺
      align-self: center;
      justify-self: center;
    }
    .sg-book-now {
      grid-area: sg_book_now; // 🗺
      align-self: center;
      justify-self: center;
    }
    .sg-carrot-btn {
      grid-area: sg_carrot_btn; // 🗺
      align-self: center;
      justify-self: center;
    }
  }
}

`;

const EchoShipAccordionComponent = (props) => {
  return (
    <AccordionComponentDisabledQuotes>
      <div className="es-c-shipment-accordion">
        <div className="es-cg-shipment-accordion">

          <div className="sg-shipment">{props.shipmentNumber}</div>
          <div className="sg-status">{props.shipmentStatus}</div>

          <div className="sg-origin">
            {props.originCity}<br />
            <span>{props.originZip}</span>
          </div>
          <div className="sg-destination">
            {props.destinationCity}<br />
            <span>{props.destinationZip}</span>
          </div>
          <div className="sg-pickup">
          {props.pickupDate}<br />
            <span>
              Estimated
              <br />
            </span>
          </div>
          <div className="sg-delivery">
          {props.carrier}
          <br />
          LTL
          </div>
          <div className="sg-carrier">

          {props.transitTime}
  
          </div>
          <div className="sg-total-cost">
            {props.estPrice}
          
          </div>
          <div className="sg-bol-btn">
          <FontAwesomeIcon icon="file-alt" style={{ color: '#0033a1' }} />
          </div>
          <div className="sg-copy-btn">
          <FontAwesomeIcon icon="copy" style={{ color: '#0033a1' }} />
          </div>

          <div className="sg-book-now">
          <ButtonDisabledBookNow 
            buttonText="Book Now"
          />
          </div>

          <div className="sg-carrot-btn" >
                  <FontAwesomeIcon icon="chevron-circle-down" />

          </div>

          

        </div>
      </div>
    </AccordionComponentDisabledQuotes>
  )
}

export default EchoShipAccordionComponent;